import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {toastr} from 'helper/toastrIntercept';
import '../../../components/table/react-table.css';
import {popupAction} from '../../../actions';
import {settingConstants} from '../../../constants';
import {settingService} from '../../../services';
import MISDialog from '../MISDialog';
import './TagSettingPopup.css';
import TagSettingView from "./TagSettingView";
import {getMixString} from "../../../language/languageUtils";
import {useMISOpt} from "../../../components/misopt";

const TagSettingPopup = ({id, tagListType, showDevice, devices, showTagValue = true, selectedTags, onApply, onClose, zIndexChange = false}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {misopt: {authority: {ETC}}} = useMISOpt();

    const [data, setData] = useState({
        // loading: false,
        // tagList: [],
        // orgList: [],
        selectedTagList: selectedTags || [],
        originalTagList: selectedTags.map(tag => {
            return {...tag}
        }),
    });

    const updateSelectedTags = (selectedTags) => {
        setData({...data, selectedTagList : selectedTags});
    }

    const [tagDevices, setTagDevice] = useState(devices || []);

    const onApplyBtn = () => {
        if(onApply) {
            onApply(selectedTagList, tagDevices);
        }
    };

    const onCloseBtn = () => {
        onClose(originalTagList);
    };

    const onChangeDevices = selectedDevices => {
        setTagDevice(selectedDevices);
    };

    const onAdd = () => {
        dispatch(popupAction.addPopup({
            title: t('MIS_SID_ADD_TAG'),
            type: settingConstants.ADD_TAG_POPUP,
            id: settingConstants.ADD_TAG_POPUP,
            zIndexChange: zIndexChange,
            onSave: (tag, tagValues) => handleAddTag(tag, tagValues),
            onClose: () => dispatch(popupAction.closePopup(settingConstants.ADD_TAG_POPUP))
        }));

        if(onClose) {
            onClose(originalTagList);
        }
    };

    const handleAddTag = (tag, tagValues) => {
        if(tag.tagName =='') {
            toastr.error(t(getMixString(['MIS_SID_MIX_ENTER_A_NAME','MIS_TEXT_TAG_P'])));
            return;
        }

        if(tag.tagName.length >100) {
            toastr.error(t('MESSAGE_COMMON_MAX_50_P'));
            return
        }

        if(tag.tagName.trim() =='') {
            toastr.error(t('MIS_MESSAGE_COMMON_CANT_TEXT_SPACE_P'));
            return;
        }

        const reg = /[\{\}\[\]\s\/?.,;:|\)*~`!^\+<>@\#$&\\\=\(\'\"%]/gi;
        if(reg.test(tag.tagName)){
            toastr.error(t('MESSAGE_DEVICE_CHECK_INPUT_CHAR_P').replace(/\\/gi, ''));
            return ;
        }

        const tagForRequest = {
            tagDesc: tag.tagDesc,
            tagName: tag.tagName,
            tagOrgan: tag.tagOrgan,
            tagType: tag.tagType,
            tagValue: '',
        };

        settingService.addTag(tagForRequest).then(res => {
            if(res.status === 'Success') {
                toastr.success(t("MIS_TEXT_SUCCESS_SENT_P"));
                if(tag.tagType === 0 && tagValues !== null && tagValues.length > 0) {
                    callAddTagCondition(res.items.tagId, tagValues, 0);
                }
            }
        }).catch(err => {
            toastr.error(err.statusText);
        }).finally(()=>{
            dispatch(popupAction.closePopup(settingConstants.ADD_TAG_POPUP));
        });

    };

    const callAddTagCondition = (tagId, tagValues, index) => {
        if((tagValues.length) === index) {
            return;
        }
        settingService.addTagCondition(tagId, {tagCondition: tagValues[index]}).then(
            res => {
                if(res.status === 'Success') {
                    callAddTagCondition(res.items.tagId, tagValues, (index + 1));
                }
            }
        ).catch(error => {return false;});
    };

    const {selectedTagList, originalTagList} = data;

    let width = 960;
    if(!showDevice) {
        width = 782;
    }

    return (
        <MISDialog
            dialog={{
                popupId: id,
                title: tagListType === 'media' ? t('MIS_SID_MEDIA_TAG_SETTINGS') : t('MIS_SID_VARIABLE_TAG_SETTINGS'),
                modal: true,
                isDraggable: false,
                closeOnEscape: true,
                width,
                height: 430,
                onClose: onCloseBtn,
            }}
            buttons={{
                leftButtons: [
                    {
                        title: t('MIS_SID_ADD_TAG'),
                        disable: !ETC.SERVER_SETUP_MANAGE,
                        onClick: onAdd,
                    },
                ],
                rightButtons: [
                    {
                        title: t('BUTTON_APPLY'),
                        onClick: onApplyBtn,
                    },
                    {
                        title: t('BUTTON_CANCEL_P'),
                        onClick: onCloseBtn,
                    }
                ]
            }}>
            <TagSettingView tagListType={tagListType}
                            showDevice={showDevice}
                            devices={tagDevices}
                            showTagValue={showTagValue}
                            selectedTags={selectedTags}
                            onChangeDevices={onChangeDevices}
                            updateSelectedTags={updateSelectedTags}
            />
        </MISDialog>
    );
};

TagSettingPopup.defaultProps = {
    selectedTags: [],
};

export default TagSettingPopup;