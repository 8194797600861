export const ErrorConstants = {
    400000: "Bad Request",
    400001: "%s is required value.",
    400002: "MESSAGE_SCHEDULE_DATA_SAVE_FAILED_P",
    400003: "The %s must be greater than or equal to %s.",
    400004: "The %s must be less than or equal to %s.",
    400005: "COM_SID_SCHEDULE_MIX_MAX_LENGTH",
    400006: "The %s must match '%s'.",
    400007: "The %s does not allowed pattern like '%s'.",
    400008: "The %s must be %s type.",
    400009: "DID_SCHEDULE_DUPLICATENAME",
    400010: "No changes have been made.",
    400011: "The %s connection is failed.",
    400012: "MESSAGE_CONTENT_RECYCLE_BIN_IS_EMPTY_P",
    400013: "The expired content cannot be added.",
    400014: "The value corresponding to RequestBody is invalid.",
    400015: "The %s is invalid. This value is not supported.",
    400016: "The login information does not exist for the user.",
    400017: "This %s does not belong to %s",
    400018: "The %s in use elsewhere can not be deleted.",
    400019: "The %s is not included in the group.",
    400020: "The %s value cannot be null or empty.",
    400021: "Enter the %s name.",
    400022: "The %s is incorrect.",
    400037: "Please delete multi-organization group before deleting the organization.",

    //  Ruleset (400200 ~ 400299)
    400200: "You cannot temporarily save a ruleset in use on a schedule.",
    400202: "MESSAGE_DEVICE_NOT_MOVE_ORG_P",
    //  Contents (400300 ~ 400399)
    400300: "You failed to login to the %s server. Please check your login information.",
    400301: "One or more parameter",
    400302: "You cannot edit while download is in progress.",
    400303: "The remote directory is invalid.",
    400304: "The multipart type request is required",
    400305: "The %s file type does not support content upload.",

    //  Playlist (400400 ~ 400499)

    //  Schedule (400500 ~ 400599)
    400510: "MESSAGE_SCHEDULE_RESERVE_DATE_LAGER_TODAY_P",
    400512: "Found expired content.",
    400500: "Check the time field values.",
    400501: "The LFD content is not played on the multi frame schedule.",
    400502: "The ADVERTISEMENT type program can have only single channel.",
    400503: "The ADVERTISEMENT type program can have only single frame.",
    400504: "The slot count does not match the number of slot data entered in the list.",
    400505: "The frame ID in the schedule data and the frame ID of the slot included in the frame data do not match.",
    400506: "The slot ID in the frame data and the slot ID of the event included in the slot data do not match.",

    //  Device (400600 ~ 400699)
    400600: "More than %s device IDs have been entered.",
    400601: "The group ID to be moved is a VWL group",
    400602: "The size of %s is exceed.",
    400603: "It is possible when all devices are connected.",
    400604: "There are too many files. The maximum number of files is %s.",
    400605: "You cannot delete VWL file mapped with group.",
    400606: "You need to check the priority value of the device and the device group.",
    400607: "The license quantity exceeded.",
    400608: "The device belongs to VWL group",
    400609: "The group ID to be moved is a sync play group",
    400610: "This file type does not support software upload.",
    400611: "The device approval maximum exceeded.",

    //  User (400700 ~ 400799)
    400700: "Enter the user ID.",
    400701: "Enter your new password.",
    400702: "Enter your new password again.",
    400703: "The passwords do not match. Please try again.",
    400704: "Your password cannot include your user ID.",
    400705: "For your password",
    400706: "New password cannot be the same as old password.",
    400707: "Your password cannot include your phone number.",
    400708: "Recently used passwords cannot be used.",
    400709: "Blank space not allowed.",
    400710: "This organization does not use the LDAP.",
    400711: "Only Server Administrator can connect.",
    400712: "You cannot move to current organization.",
    400713: "MESSAGE_USER_NOT_DEL_ROLE_EXIST_USER_P",
    400714: "You cannot delete the default roles.",
    400715: "You cannot change the user role of the administrator.",
    400716: "At least one administrator is required for an organization.",
    400717: "The login user cannot create new user.",
    400718: "You can assign Rulemanager ability only for the Administrators Group.",
    400719: "Unable to delete yourself.",
    400720: "The user ID does not exist or you cannot transfer administrator privileges to the user ID.",
    400721: "Please select at least 2 organizations to create an Organization Group.",
    400722: "Enter the confirm password.",
    400723: "A password must be 10 to 50 characters long.",
    400724: "The confirmation password must be identical with the new password.",
    400725: "MIS_SID_20_THE_ID_IS_ALREADY_IN_USE",
    400726: "User ID only contain alphanumeric characters and periods",
    400727: "Choose a password of 10 to 50 alphanumeric characters",
    400728: "Use a password of 10 to 50 characters",
    400729: "MIS_SID_SERVER_THE_AUTHENTICATION_CODE_IS_INCORRECT_PLEASE_TRY_AGAIN",
    400730: "organization name cannot be ROOT.",
    400731: "COM_TV_THE_PASSWORDS_DO_NOT_MATCH_PLEASE_TRY_AGAIN_KR_LFD",
    400732: "MESSAGE_COMMON_WRONG_PASSWORD_P",
    400733: "You cannot change the user role of the administrator.",
    400734: "Invalid e-mail address format. Enter a correct e-mail address.",
    400735: "You cannot delete the default roles.",
    400736: "You cannot edit the default roles.",
    400737: "The user ID you entered is already in use.<br>Please enter a different ID.",
    400738: "The selected group does not use LDAP.",
    400739: "The selected group is a part of the organization not using the LDAP sync.",
    400740: "Your password has expired. Please change your password.",
    400741: "MESSAGE_USER_CHK_ADMIN_ID_P",
    400745: "MESSAGE_COMMON_WRONG_PASSWORD_P",
    
    //  Settings (400800 ~ 400899)
    400800: "The main server cannot be deleted.",
    400801: "The server name you entered is already in use. Enter a different server name.",
    400802: "You cannot delete a running server. Please remove after stopping.",
    400803: "message.license_error_8",
    400804: "message.license_error_900",
    400805: "message.license_error_4315",
    400806: "message.license_error_13000",
    400807: "message.license_error_12000",
    400808: "COM_MIS_TEXT_LICENSE_IS_BEING_USED_P",
    400809: "MIS_TEXT_LICENSE_KEY_FOR_THE_SELECTED_PRODUCT_IS_NOT_P",
    400810: "Please enter the time rightly hour between 0 and 12.",
    400811: "No SMTP information in the server settings.",
    400812: "Unable to withdraw the administrator.",
    400813: "MIS_TEXT_DOES_NOT_SUPPORT_THE_PRODUCT_P",
    400814: "The free license could not be deleted.",
    400815: "message.license_error_19002",
    400816: "Please contact the SLM license. Error Code : %s",
    400817: "H/W Unique Key is invalid.",
    400818: "MIS_TEXT_FREE_LICENSE_COULD_NOT_BE_ADDED_P",
    400819: "Failed to delete a license. (Deactivation key is invalid)",
    400820: "MIS_TEXT_CANNOT_ISSUE_FREE_LICENSE_ANYMORE_P",
    400821: "DID_SCHEDULE_DUPLICATENAME",
    400822: "COM_DOWNLOAD_FAILED",
    400823: "MESSAGE_COMMON_CANT_EDIT_ORG_P",
    400824: "MESSAGE_COMMON_CANT_EDIT_ORG_P",

    //  Edge (400900 ~ 400999)
    400900: "Device FTP authentication failed. Check device ID or Password.",
    400901: "The requested EdgeServer is not found.",
    400902: "EdgeServer",
    400903: "EdgeServer hostName",
    400904: "Activate Edge Server License.",
    400905: "Inappropriate server name.",
    400906: "MIS_SID_CAFEB_NOT_DEL_MASTER_SERVER_WITH_SLAVE",
    400907: "MIS_SID_CAFEB_MASTER_SERVER_WITH_SLAVE_NOT_CHANGE_SLAVE",


    //HTTP_STATUS=401
    //  Common (401000 ~ 401199)
    401000: "MIS_SID_20_THE_USER_IS_NOT_AUTHORIZED",
    401001: "You are unauthorized. Access denied.",
    401002: "Login failed.",
    401003: "Token is expired.",
    401004: "Refresh token is expired.",
    401007: "MIS_SID_MIX_ENTERD_INCORRECT_PW_BLOCKED_TEMP",
    401008: "MIS_SID_20_THE_USER_IS_NOT_AUTHORIZED",
    
    //  Ruleset (401200 ~ 401299)
    //  Contents (401300 ~ 401399)
    //  Playlist (401400 ~ 401499)
    //  Schedule (401500 ~ 401599)
    //  Device (401600 ~ 401699)
    //  User (401700 ~ 401799)
    //  Settings (401800 ~ 401899)
    //  Etc (401900 ~ 401999)


    //HTTP_STATUS=403
    //  Common (403000 ~ 403199)
    403000: "Forbidden",
    403001: "ERROR_NO_ROLE",
    403002: "You are access denied.",
    403003: "You do not have %s permission.",

    //  Ruleset (403200 ~ 403299)
    //  Contents (403300 ~ 403399) 
    403300: "The user ID does not have permission." ,
    403301: "MIS_MESSAGE_CONTENT_CANNOT_EDIT_OTHERS_CONTENT_GROUP_P",
    //  Playlist (403400 ~ 403499)
    //  Schedule (403500 ~ 403599)
    //  Device (403600 ~ 403699)
    //  User (403700 ~ 403799)
    403700: "The user ID does not have permission.",
    403705: "ERROR_NO_ROLE",

    //  Settings (403800 ~ 403899)
    //  Etc (403900 ~ 403999)


    //HTTP_STATUS=404
    //  Common (404000 ~ 404199)
    404000: "Data not found",
    404001: "MESSAGE_COMMON_NO_DATA_P",

    //  Ruleset (404200 ~ 404299)
    //  Contents (404300 ~ 404399)
    404300: "The THUMBNAIL_HOME is not found on config data.",

    //  Playlist (404400 ~ 404499)
    //  Schedule (404500 ~ 404599)
    404500: "No content list can be found that matches the playlist ID.",
    404501: "No playlist can be found that matches the content ID of event schedule.",

    //  Device (404600 ~ 404699)
    404600: "The device group information for your organization does not exist.",

    //  User (404700 ~ 404799)
    404700: "The LDAP organization is not obtained.",

    404701: "The authentication token is not found.",
    //  Settings (404800 ~ 404899)
    404800: "External Link Not Found",
    //  Etc (404900 ~ 404999)


    //HTTP_STATUS=405
    //  Common (405000 ~ 405199)
    405000: "Method not allowed",

    //  Ruleset (405200 ~ 405299)
    //  Contents (405300 ~ 405399)
    //  Playlist (405400 ~ 405499)
    //  Schedule (405500 ~ 405599)
    //  Device (405600 ~ 405699)
    //  User (405700 ~ 405799)
    //  Settings (405800 ~ 405899)
    //  Etc (405900 ~ 405999)


    //HTTP_STATUS=408 (408000 ~ 408199)
    //  Common 
    408000: "Request timeout",
    408001: "It is timed out the service response.",

    //  Ruleset (408200 ~ 408299)
    //  Contents (408300 ~ 408399)
    408300: "It is timed out the FTP connection.",
    408301: "It is timed out the CIFS connection.",

    //  Playlist (408400 ~ 408499)
    //  Schedule (408500 ~ 408599)
    //  Device (408600 ~ 408699)
    408600: "It is timed out the device connection.",

    //  User (408700 ~ 408799)
    408700: "MIS_SID_SERVER_THE_AUTHENTICATION_CODE_HAS_EXPIRED_PLEASE_TRY_AGAIN",

    //  Settings (408800 ~ 408899)
    //  Etc (408900 ~ 408999)
    408900: "Request has been canceled.",


    //HTTP_STATUS=500
    //  Common (500000 ~ 500199)
    500000: "Internal Server Error",
    500001: "Unknown error occurred.",
    500002: "Unexpected condition was encountered.",
    500003: "SQL Error was encountered.",
    500004: "Config error was encountered.",
    500005: "Job scheduler error was encountered.",
    500006: "An error occurred when cloning an object.",
    500007: "Bindining error occured",
    500008: "Failed to save %s",

    //  Ruleset (500200 ~ 500299)
    500200: "It is failed to create the rule meta file.",
    500201: "An error occurred when updating the ruleset file.",

    //  Contents (500300 ~ 500399)
    500300: "The Web content creation is failed.",
    500301: "An error occurred while creating the thumbnail file.",
    500302: "MESSAGE_COMMON_ERROR_P",
    500303: "It is failed to save the share folder information.",

    //  Playlist (500400 ~ 500499)
    500400: "It is failed to copy the playlist.",

    //  Schedule (500500 ~ 500599)
    500500: "It is failed to create the schedule file.",
    500501: "It is failed to deploy the created content schedule.",

    //  Device (500600 ~ 500699)
    500600: "It is failed to run the postboot service.",
    500601: "It is failed to upload the software file.",
    500602: "It is failed to deploy the software file to devices.",
    500603: "It is failed to update the software file.",
    500604: "It is failed to clean up the log folder and log files.",
    500605: "It is failed to create the vwl file.",
    500606: "It is failed to run the vwl layout deployment service.",
    500607: "An error occurred while sending the statistics file refresh request.",
    500608: "An error occurred while updating the requested time of statistics file.",
    500609: "An error occurred when getting device setup information.",
    500610: "It is failed to update the tag information for device.",
    500611: "It is failed to create a home directory of the RM Rule file.",
    500612: "It is failed to create the RM Monitoring directory.",
    500613: "It is failed to the E2E deactivation.",
    500614: "It is failed to deploy the schedule.",
    500615: "It can be available when all devices are connected.",

    //  User (500700 ~ 500799)
    500700: "It is failed to create a new organization group.",
    500701: "It is failed to delete the organization group.",

    //  Settings (500800 ~ 500899)
    500801: "MIS_MESSAGE_WRONG_LDAP_SERVER_ADDRESS_P",
    500802: "MIS_MESSAGE_ID_PASSWORD_ENTERED_WRONG_P",
    500803: "MIS_MESSAGE_EXPIRED_PASSWORD_P",
    500804: "MIS_TEXT_CIFS_SERVER_LOGIN_FAILURE_P",
    500805: "MIS_MESSAGE_DN_INFO_WRONG_P",
    500806: "MIS_MESSAGE_TIME_OUT_P",
    500807: "MIS_MESSAGE_LDAP_SERVER_CONNECT_FAILED_P",
    500808: "It is failed to update of Datalink server. Please try again.",
    500809: "It is failed to update of Remote server. Please try again.",
    500810: "SLM DB ERROR",
    //  Etc (500900 ~ 500999)


    //HTTP_STATUS=503
    //  Common (503000 ~ 503199)
    503000: "Service Unavailable",

    //  Ruleset (503200 ~ 503299)
    //  Contents (503300 ~ 503399)
    //  Playlist (503400 ~ 503499)
    //  Schedule (503500 ~ 503599)
    //  Device (503600 ~ 503699)
    //  User (503700 ~ 503799)
    503700: "COM_PLEASE_WAIT_A_MOMENT_AND_TRY_AGAIN",
    //  Settings (503800 ~ 503899)
    503800: "MESSAGE_USER_CANT_WITHDRAWAL_P",
    //  Edge (503900 ~ 503999)
    503901: "EdgeServer Registration failed.",
    503902: "EdgeServer Deletion fail",
    503903: "EdgeServer Hostname update fail",

    503900: "MIS_SID_20_NO_SMTP_INFORMATION_IN_THE_SERVER_SETTINGS"

}

