import React from 'react';
import './SubMenuContainer.css';
import Button from "../components/button/Button";
import classNames from 'classnames';
import {SubmenuContentsContainer} from "./schedule";
import {contentAction, menuAction, playlistAction, popupAction} from "../actions";
import {connect} from "react-redux";
import GroupContainer from "./common/GroupContainer";
import SubmenuMessageContainer from "./schedule/message/SubmenuMessageContainer";
import {commonConstants} from "../constants/CommonConstants";
import SubmenuEventConditionContainer from "./schedule/events/SubmenuEventConditionContainer";
import SubmenuEventContainer from "./schedule/events/SubmenuEventContainer";
import {SubmenuRulesetContainer} from "./ruleset";
import {withTranslation} from "react-i18next";
import PerfectScrollbar from 'perfect-scrollbar';
import ReactDOM from 'react-dom';
import {contentService, rulesetService, scheduleService, settingService} from "../services";
import SubmenuSimpleScheduleContainer from "./schedule/content/SubmenuSimpleScheduleContainer";
import {MISOptContext} from "../components/misopt";
import {getErrorMessage} from "../helper/responseHandler";
import {toastr} from 'helper/toastrIntercept';
import {CONTENT_SCHEDULE_PROGRAM_TYPE_AD} from '../constants/ScheduleConstants';
import {Loading} from "../components/loading/Loading";
import {settingConstants} from "../constants";

class SubMenuContainer extends React.Component {

    static contextType = MISOptContext;

    constructor(props) {
        super(props);
        this.state = {
            style : {
                height: '100%',
                marginLeft: 0
            },
            submenuStyle: {
                height: '100%'
            },
            loadingForGroup : false
        }
        this.ps = undefined;
    }

    getAuthority(id) {
        if(id === 'CONTENT' || id === 'PLAYLIST' || id === 'RULESET') {
            id = 'CONTENT_PLAYLIST_RULESET';
        }

        const {authority}  = this.context;
        let auth = {};
        if (authority !== undefined && id !== undefined) {
            auth = authority[id];
        }
        return auth !== undefined ? auth : {};
    }

    componentDidMount() {
        this._updateDimensions();
        window.addEventListener("resize", this._updateDimensions.bind(this));
        if (this !== undefined && ReactDOM.findDOMNode(this) !== undefined && ReactDOM.findDOMNode(this) !== null) {
            const scrollWrap = ReactDOM.findDOMNode(this).querySelector('#submenu_scroll_wrap');
            if (scrollWrap !== null) {
                this.ps = new PerfectScrollbar('#submenu_scroll_wrap', {
                    wheelSpeed: 2,
                    wheelPropagation: true,
                    minScrollbarLength: 20,
                    suppressScrollX: true
                });
            }
        }
    }

    updateScrollbarPosition = () => {
        if (this.ps !== undefined) {
            this.ps.update();
        }
    }

    _updateDimensions() {
        const {menu} = this.props;
        let height = 145;
        if (menu !== undefined && menu.submenu !== undefined && menu.submenu.id !== undefined) {
            switch (menu.submenu.id) {
                case 'RULESET':
                case 'CONTENT':
                case 'PLAYLIST':
                case 'SCHEDULE':
                    //height = 280;
                    height = 148;
            }
        }

        const updateHeight = window.innerHeight - height;
        this.setState({
                submenuStyle: {
                    height: updateHeight
                }
            }
        )
    }

    componentDidUpdate(prevProp, prevState) {
        if (this.props.menu.submenu !== prevProp.menu.submenu) {
            const {menu} = this.props;
            const {style} = this.state;
            if (!menu.submenu.hasSubmenu) {
                this.setState({
                    style: {
                        ...style,
                        marginLeft: -253
                    }
                });
            } else {
                this.setState({
                    style: {
                        ...style,
                        marginLeft: 0
                    }
                })
            }
        }

        this.updateScrollbarPosition();

    }

    clickChildren(e, node, children) {
        e.stopPropagation();
        const {activeChildrenNode, menu, moveTab} = this.props;
        if (!children.hasGroup) {
            if(menu.currentContentId === 'LED_CABINET') {
                moveTab({id: 'DEVICE'}, node.id, children.id);
            }
            else if(children.id.startsWith("EXTERNAL_LINK_")) {
                activeChildrenNode(node.id, children.id, "EXTERNAL_LINK:" + children.externalLinkUrl);
            }
            else {
                activeChildrenNode(node.id, children.id);
            }
        } else {
            const {activeChildGroupNode} = this.props;
            if(menu.currentContentId === 'LED_CABINET') {
                moveTab({id: 'DEVICE'}, node.id, children.id);
            } else {
                activeChildGroupNode(node.id, children.id);
                if (children.group.length === 0) {
                    this.getGroupTree(menu.submenu.id, children.id);
                }
            }
        }

    }

    clickNode(e, submenuId, node) {
        e.stopPropagation();

        if (!node.hasGroup) {
            const {activeNode, menu, moveTab} = this.props;
            if(node.children.length === 0 && menu.currentContentId === 'LED_CABINET') {
                moveTab({id: 'DEVICE'}, node.id);
            }

            else if(node.id === 'EXTERNAL_LINK' && !node.open) {
                const {updateSubmenuChildren, t} = this.props;

                settingService.fetchExternalLink().then(res => {
                    const newChildren = [];
                    if(res.status === "Success") {
                        res.items.map((item) => {
                            newChildren.push({
                                id: "EXTERNAL_LINK_" + item.linkId,
                                title: item.linkId,
                                externalLinkUrl: item.linkUrl,
                                active: false,
                                children: [],
                                hasGroup: false,
                                icon: "",
                                number: "",
                                enable: true
                            });
                        });

                        if(newChildren.length > 0) {
                            updateSubmenuChildren(node.id, newChildren);
                            activeNode(node.id);
                        }
                        else {
                            toastr.warning(t("MESSAGE_COMMON_NO_DATA_P"));
                        }
                    }
                }).catch(e => {
                    toastr.error(getErrorMessage(e));
                });
            }

            else {
                activeNode(node.id);
            }
        } else {
            const {activeGroupNode} = this.props;
            if (node.group.length === 0) {
                this.getGroupTree(submenuId, node.id);
            }else{
                activeGroupNode(node.id,
                    node.id === "RULESET_BY_GROUP"
                    || node.id === "MY_CONTENT"
                    || node.id === "SHARED_CONTENT"
                    || (submenuId === "CONTENT" && node.id === "BY_USER")
                    || (submenuId === "USER" && node.id === "USER_BY_GROUP")
                    || (submenuId === "PLAYLIST" && node.id === "PLAYLIST_BY_USER")
                    || (submenuId === "PLAYLIST" && node.id === "MY_PLAYLIST")
                );
            }
        }
    }

    getGroupTree(submenuId, nodeId) {
        const {loadGroup} = this.props;
        new Promise((resolve, reject) => {
            this.setState({
                loadings : {
                    [nodeId] : true
                }
            })
            resolve(loadGroup(submenuId, nodeId));
        }).then(
            () => this.setState({
                loadings : {
                    [nodeId] : false
                }
            })
        )
    }

    clickSubmenuButton(buttonId) {
        switch (buttonId) {
            case 'WEB_AUTHOR' :
                contentService.fetchWebAuthorAuthorizedUser().then(res => {
                    if(res.status === 'Success'){
                        window.open('', 'WebAuthorWindow');
                        let form = ReactDOM.findDOMNode(this).querySelector('#web-author-form');
                        form.setAttribute('action', res.items.webAuthorPath);
                        ReactDOM.findDOMNode(form).querySelector('#web-author-username').setAttribute("value", res.items.userId);
                        ReactDOM.findDOMNode(form).querySelector('#web-author-token').setAttribute("value", res.items.token);
                        ReactDOM.findDOMNode(form).querySelector('#web-author-language').setAttribute("value", res.items.language);
                        form.submit();
                    }
                }).catch(error => {
                    console.log(error);
                });
                break;
            case 'NEW_PLAYLIST':
                return this.props.addPopup({type: commonConstants.CREATE_PLAYLIST, id: 'NEW_PLAYLIST'})
            case 'NEW_RULESET':
                return this.props.addPopup({type: commonConstants.CREATE_RULESET, id: 'NEW_RULESET'});
        }

    }

    clickTrashIcon(e, id) {
        e.stopPropagation();
        const {loadTrashBasketPopup, loadTrashBasketPlaylistPopup, activeNode, loadContent} = this.props;
        switch(id){
            case "CONTENT_RECYCLE_BIN":
                loadTrashBasketPopup(true);
                activeNode(id);
                loadContent(id);
                break;
            case "PLAYLIST_RECYCLE_BIN":
                loadTrashBasketPlaylistPopup(true);
                activeNode(id);
                loadContent(id);
                break;
            case 'CONTENT_SCHEDULE_RECYCLE_BIN':
            case 'EVENT_SCHEDULE_RECYCLE_BIN':
            case 'MESSAGE_SCHEDULE_RECYCLE_BIN':
            case 'RULESET_RECYCLE_BIN':
                activeNode(id);
                loadContent(id);
                this.openConfirmPopupForRecycleBin(id);
                break;
            default:
                break;
        }
    }

    loadExternalLinkList() {
        const {t, updateSubmenuChildren} = this.props;

        settingService.fetchExternalLink().then(res => {
            const newChildren = [];
            if(res.status === "Success") {
                res.items.map((item) => {
                    newChildren.push({
                        id: "EXTERNAL_LINK_" + item.linkId,
                        title: item.linkId,
                        externalLinkUrl: item.linkUrl,
                        active: false,
                        children: [],
                        hasGroup: false,
                        icon: "",
                        number: "",
                        enable: true
                    });
                });

                updateSubmenuChildren('EXTERNAL_LINK', newChildren);

                if(newChildren.length <= 0) {
                    toastr.warning(t("MESSAGE_COMMON_NO_DATA_P"));
                }
            }
        }).catch(e => {
            toastr.error(getErrorMessage(e));
        });
    }

    clickExternalLinkAddButton(e) {
        e.stopPropagation();
        const {addPopup} = this.props;

        addPopup({
            type: settingConstants.ADD_EXTERNAL_LINK_POPUP,
            id: settingConstants.ADD_EXTERNAL_LINK_POPUP,
            onSave: () => {
                this.loadExternalLinkList();
            }
        });
    }

    clickDeleteExternalLink(e, linkId) {
        e.stopPropagation();

        const {addPopup, closePopup, t} = this.props;
        addPopup({
                id: commonConstants.COMMON_CONFIRM_POPUP,
                type: commonConstants.COMMON_CONFIRM_POPUP,
                title: t('MIS_TEXT_TITLE_EXTERNAL_LINK_P'),
                message: t('MIS_MESSAGE_EXTERNAL_LINK_DELETE_LINK_P'),
                onClickYes: () => {
                    closePopup(commonConstants.COMMON_CONFIRM_POPUP);
                    settingService.deleteExternalLink(linkId)
                        .then(res => {
                            if(res.status === 'Success') {
                                toastr.success(t('ALERT_SUCCESS_DELETE'));

                                this.loadExternalLinkList();
                            }
                        })
                        .catch(e=> {
                            toastr.error(getErrorMessage(e))
                        })
                },
                onClose: () => closePopup(commonConstants.COMMON_CONFIRM_POPUP),
        });
    }

    openConfirmPopupForRecycleBin(id) {
        const {addPopup, closePopup, t} = this.props;

        addPopup({
            id: commonConstants.COMMON_CONFIRM_POPUP,
            type: commonConstants.COMMON_CONFIRM_POPUP,
            title: t("BUTTON_EMPTY_RECYCLE_BIN_P"),
            message: t("MESSAGE_CONTENT_CONFIRM_EMPTY_RECYCLE_BIN_P"),
            onClickYes: ()=>this.emptyRecycleBin(id),
            onClose: () => closePopup(commonConstants.COMMON_CONFIRM_POPUP)
        })

    }

    emptyRecycleBin(id) {
        const {closePopup, loadContent, t, forceReloadContent} = this.props;
        switch (id) {
            case 'CONTENT_SCHEDULE_RECYCLE_BIN':
                scheduleService.emptyContentSchedule()
                    .then(res => {
                        if (res) {
                            toastr.success(t("MIS_MESSAGE_CONTENT_DELETE_CONTENT_FROM_PLAYLIST_SUCCESS_P"));
                            forceReloadContent();                           
                            loadContent(id);
                        }
                    })
                    .catch(e=> {
                        toastr.error(getErrorMessage(e))
                    })
                    .finally(closePopup(commonConstants.COMMON_CONFIRM_POPUP));
                break;
            case 'MESSAGE_SCHEDULE_RECYCLE_BIN':
                scheduleService.emptyMessageScheduleRecycleBin()
                    .then(res => {
                        if (res) {
                            toastr.success(t("MIS_MESSAGE_CONTENT_DELETE_CONTENT_FROM_PLAYLIST_SUCCESS_P"));
                            forceReloadContent();                           
                            loadContent(id);
                        }
                    })
                    .catch(e=> {
                        toastr.error(getErrorMessage(e))
                    })
                    .finally(closePopup(commonConstants.COMMON_CONFIRM_POPUP));
                break;
            case 'EVENT_SCHEDULE_RECYCLE_BIN':
                scheduleService.emptyEventSchedule()
                    .then(res => {
                        if (res) {
                            toastr.success(t("MIS_MESSAGE_CONTENT_DELETE_CONTENT_FROM_PLAYLIST_SUCCESS_P"));
                            forceReloadContent();                            
                            loadContent(id);
                        }
                    })
                    .catch(e=> {
                        toastr.error(getErrorMessage(e))
                    })
                    .finally(closePopup(commonConstants.COMMON_CONFIRM_POPUP));
                break;
            case 'RULESET_RECYCLE_BIN':
                rulesetService.emptyRecycleBin()
                    .then(res => {
                        toastr.success(t('ALERT_SUCCESS_DELETE'));
                        forceReloadContent();                        
                        loadContent(id);
                    })
                    .catch(e=> {
                        toastr.error(getErrorMessage(e))
                    })
                    .finally(closePopup(commonConstants.COMMON_CONFIRM_POPUP));
                break;
            default:
                break;
        }
    }

    renderChildren(node, children) {
        const {menu, t} = this.props;
        const depth1Menu = menu.menus.find(m => m.id === menu.submenu.id);
        if(!depth1Menu) {
            return (null);
        }
        const depth2Menu = depth1Menu.nodes.find(m => m.id === node.id);
        if(node.id === 'EXTERNAL_LINK') {
            depth2Menu.children = [];
        }
        if(!depth2Menu || depth2Menu.children==undefined) {
            return (null);
        }
        return (
            <ul className={("subMenu") +" "+(node.open != null && node.open ? 'open' : 'close')}>
                {
                    children.map(
                        child => {
                            const depth3Menu = depth2Menu.children.find(m => m.id === child.id);
                            if((!depth3Menu || !depth3Menu.enable) && !child.id.startsWith("EXTERNAL_LINK_")) {
                                return (null);
                            }
                            return <li key={child.id} className={(child.active ? 'active' : '') + (child.hasGroup?' hasSub':'') } onClick={(e)=>this.clickChildren(e, node, child)} style={{display: child.enable?'':'none' }}>
                                <a href="#">
                                    {t(child.title)}
                                    <span className={"numbering "+ ( (child.number === undefined || child.number === '' || child.number===0) ? 'zero' : '')}>{child.number > 0 ? child.number : ''}</span>
                                    {
                                        child.icon !== undefined && child.icon !== '' &&
                                            <span className={'iconwrap'} style={{right: 16}} onClick={(e) => this.clickTrashIcon(e, child.id)} >
                                                <button className="basket"></button>
                                            </span>

                                    }
                                    {
                                        child.id.startsWith("EXTERNAL_LINK_") &&
                                            <span className={'iconwrap'} style={{right: 16}} onClick={(e) => this.clickDeleteExternalLink(e, child.title)} >
                                                <button className="remove"></button>
                                            </span>
                                    }
                                </a>
                                {
                                    child.hasGroup !== undefined
                                    && child.hasGroup
                                    && this.state.loadings !== undefined
                                    && this.state.loadings[child.id] !== undefined
                                    && this.state.loadings[child.id] === true &&
                                    <div style={{display: 'flex', justifyContent: 'center'}}><Loading size={10}/></div>
                                }
                                {
                                    child.hasGroup !== undefined
                                    && child.hasGroup
                                    && child.group !== undefined
                                    && child.group.length > 0 &&
                                    <GroupContainer submenuId={menu.submenu.id} {...child} updateScrollbarPosition={this.updateScrollbarPosition}/>
                                }
                            </li>
                        }
                    )
                }
            </ul>
        )
    }

    render() {
        const {menu, schedules, rulesets, t, cache} = this.props;
        const {messages, events, contents} = schedules;
        let showButtonWrap = true;
        if (contents !== undefined && contents.simple !== undefined && contents.simple) {
            showButtonWrap = false;
        }

        const submenuWidth = menu.submenuWidth;
        const showSubmenu = menu.submenu.hasSubmenu && menu.submenu.showSubmenu;

        const showSimpleSchedule = (menu.currentContentId  === 'ALL_CONTENT_SCHEDULE' || menu.currentContentId === 'CONTENT_SCHEDULE_BY_GROUP') && contents.simpleSchedule !== undefined && contents.simpleSchedule.length > 0 && contents.simpleSchedule[0].programType !== CONTENT_SCHEDULE_PROGRAM_TYPE_AD || false;

        const authority = this.getAuthority(menu.submenu.id);

        return(
            <div className="lnbWrap noSelect" style={{marginLeft: showSubmenu ? 0 : -(submenuWidth), width: submenuWidth}}>
                {
                    showSimpleSchedule &&
                        <SubmenuSimpleScheduleContainer/>
                }
                {
                    menu !== null && menu.submenu !== null && menu.submenu.id === 'NEW_ADVERTISEMENT' &&
                        <SubmenuSimpleScheduleContainer programType={contents.advertisement.new.programType} deviceType={contents.advertisement.new.deviceType} deviceTypeVersion={contents.advertisement.new.deviceTypeVersion}/>
                }
                {
                    menu !== null && menu.submenu !== null && menu.submenu.id === 'EDIT_ADVERTISEMENT' &&
                        <SubmenuSimpleScheduleContainer programType={contents.advertisement.edit.programType} deviceType={contents.advertisement.edit.deviceType} deviceTypeVersion={contents.advertisement.edit.deviceTypeVersion}/>
                }
                {
                    menu != null && menu.submenu != null && menu.submenu.id === 'NEW_SCHEDULE' &&
                        <SubmenuContentsContainer programType={contents.new.program.programType} deviceType={contents.new.program.deviceType} deviceTypeVersion={contents.new.program.deviceTypeVersion} submenuId={'NEW_SCHEDULE'} cache={cache()}/>
                }
                {
                    menu != null && menu.submenu != null && menu.submenu.id === 'EDIT_SCHEDULE' &&
                        <SubmenuContentsContainer programType={contents.edit.program.programType} deviceType={contents.edit.program.deviceType} deviceTypeVersion={contents.edit.program.deviceTypeVersion} submenuId={'EDIT_SCHEDULE'} cache={cache()}/>
                }
                {
                    // render message list for new
                    menu != null && menu.submenu != null && menu.submenu.id === 'NEW_MESSAGE' &&
                    messages !== undefined && messages.newMessage !== undefined && messages.newMessage.message != undefined && messages.newMessage.message.messageList !== undefined && messages.newMessage.message.messageList.length > 0 ?
                        <SubmenuMessageContainer type={"new"}/> : ''
                }
                {
                    // render message list for edit
                    menu != null && menu.submenu != null && menu.submenu.id === 'EDIT_MESSAGE' &&
                    messages !== undefined && messages.editMessage !== undefined && messages.editMessage.message != undefined && messages.editMessage.message.messageList !== undefined && messages.editMessage.message.messageList.length > 0 ?
                        <SubmenuMessageContainer type={"edit"}/> : ''
                }
                {
                    // render event manager list
                    menu != null && menu.submenu != null && menu.submenu.id === 'NEW_EVENT_CONDITION' &&
                    events !== undefined && events.condition !== undefined && events.condition.new != undefined ?
                        <SubmenuEventConditionContainer type={"new"} />  : ''
                }
                {
                    // render event manager list
                    menu != null && menu.submenu != null && menu.submenu.id === 'EDIT_EVENT_CONDITION' &&
                    events !== undefined && events.condition !== undefined && events.condition.edit != undefined ?
                        <SubmenuEventConditionContainer type={"edit"} />  : ''
                }
                {
                    // render event list
                    menu != null && menu.submenu != null && menu.submenu.id === 'NEW_EVENT' &&
                    events !== undefined && events.schedules !== undefined && events.schedules.new != undefined ?
                        <SubmenuEventContainer type={"new"} />  : ''
                }
                {
                    // render event list
                    menu != null && menu.submenu != null && menu.submenu.id === 'EDIT_EVENT' &&
                    events !== undefined && events.schedules !== undefined && events.schedules.edit != undefined ?
                        <SubmenuEventContainer type={"edit"} />  : ''
                }
                {
                    // render submenu buttons
                    !showSimpleSchedule && showButtonWrap && menu != null && menu.submenu.buttons != null && menu.submenu.buttons.length > 0 &&
                        <div className="lnb_buttonWrap">
                            {
                                menu.submenu.id === 'SCHEDULE' && authority !== undefined && (authority.CREATE || authority.MANAGE) && menu.submenu.buttons.map(
                                    (button, index) => {
                                        return (<Button key={index} {...button} onClick={()=>this.clickSubmenuButton(button.id)} width={button.id !== undefined && button.id === 'NEW_SCHEDULE' ? 206 : undefined}/>)
                                    }
                                )
                            }
                            {
                                menu.submenu.id === 'RULESET' && authority !== undefined && (authority.CREATE || authority.MANAGE) && menu.submenu.buttons.map(
                                    (button, index) => {
                                        return (<Button key={index} {...button} onClick={()=>this.clickSubmenuButton(button.id)} width={button.id !== undefined && button.id === 'NEW_SCHEDULE' ? 206 : undefined}/>)
                                    }
                                )
                            }
                            {
                                menu.submenu.id === 'CONTENT' && authority !== undefined && (authority.CREATE || authority.MANAGE) && menu.submenu.buttons.map(
                                    (button, index) => {
                                        return (<Button key={index} {...button} onClick={()=>this.clickSubmenuButton(button.id)} style={button.id !== undefined && button.id === 'WEB_AUTHOR' ? {width:100} : undefined} width={100} zIndex={1000}/>)
                                    }
                                )
                            }
                            {
                                menu.submenu.id === 'PLAYLIST' && authority !== undefined && (authority.CREATE || authority.MANAGE) && menu.submenu.buttons.map(
                                    (button, index) => {
                                        return (<Button key={index} {...button} onClick={()=>this.clickSubmenuButton(button.id)} width={button.id !== undefined && button.id === 'NEW_PLAYLIST' ? 206 : undefined}/>)
                                    }
                                )
                            }
                            {
                                menu.submenu.id !== 'SCHEDULE' && menu.submenu.id !== 'RULESET' && menu.submenu.id !== 'CONTENT' && menu.submenu.id !== 'PLAYLIST' && menu.submenu.buttons.map(
                                    (button, index) => {
                                        return (<Button key={index} {...button} onClick={()=>this.clickSubmenuButton(button.id)} width={button.id !== undefined && button.id === 'NEW_SCHEDULE' ? 206 : undefined}/>)
                                    }
                                )
                            }
                        </div>
                }
                {
                    showButtonWrap && menu != null && menu.submenu.buttons != null && menu.submenu.buttons.length > 0 && menu.submenu.id === 'CONTENT' &&
                    <form id='web-author-form' target='WebAuthorWindow' method='post'>
                        <input id='web-author-username' type="hidden" name="username"/>
                        <input id='web-author-token' type="hidden" name="token"/>
                        <input id='web-author-language' type="hidden" name="language"/>
                    </form>
                }
                {
                    menu != null && menu.submenu != null && menu.submenu.id === 'NEW_RULESET' && rulesets.newRuleset &&
                        <SubmenuRulesetContainer type={menu.submenu.id} key={menu.submenu.key.toString()} deviceType={rulesets.newRuleset.ruleset.deviceType} deviceTypeVersion={rulesets.newRuleset.ruleset.deviceTypeVersion} subMenuWidth={submenuWidth}/>
                }
                {
                    menu != null && menu.submenu != null && menu.submenu.id === 'EDIT_RULESET' && rulesets.editRuleset &&
                        <SubmenuRulesetContainer type={menu.submenu.id} key={menu.submenu.key.toString()} deviceType={rulesets.editRuleset.ruleset.deviceType} deviceTypeVersion={rulesets.editRuleset.ruleset.deviceTypeVersion} subMenuWidth={submenuWidth}/>
                }
                
                <div className="lnb" style={{display: !showSimpleSchedule && (menu != null && menu.submenu.nodes != null && menu.submenu.nodes.length > 0 === true) ? '': 'none'}}>
                    <ul className="lnbSubMenu" id={'submenu_scroll_wrap'} style={{overflowY: 'auto', height:this.state.submenuStyle.height}}>
                        {
                            menu != null && menu.submenu.nodes != null && menu.submenu.nodes.length > 0 &&
                                <div className={"divider"}></div>
                        }
                        {
                            // render submenu
                            menu != null && menu.submenu.nodes != null && menu.submenu.nodes.length > 0 &&
                                menu.submenu.nodes.map(
                                        (node, index) =>
                                        {
                                            const depth1Menu = menu.menus.find(m => m.id === menu.submenu.id);
                                            if(!depth1Menu || !depth1Menu.enable) {
                                                return null;
                                            }
                                            const depth2Menu = depth1Menu.nodes.find(m => m.id === node.id);
                                            if(!depth2Menu || !depth2Menu.enable) {
                                                return null;
                                            }

                                            return(
                                                <li key={index} className={classNames({'lnbMenu':true,
                                                                                   'active' : node.active,
                                                                                   'hasSub' : typeof  node.open !== 'undefined' || node.children.length > 0})}
                                                onClick={(e)=>this.clickNode(e, menu.submenu.id, node)} style={{display: node.enable?'':'none' }}>
                                                <a href="#">{t(node.title)}
                                                    <span className={"numbering "+ ((node.number === undefined || node.number === '' || node.number===0) ? 'zero' : '')}>{node.number > 0 ? node.number : ''}</span>
                                                    {
                                                        node.useBasket && <span className='iconwrap'><button className="basket" onClick={(e) => this.clickTrashIcon(e, node.id)} /></span>
                                                    }
                                                    {
                                                        node.id === 'EXTERNAL_LINK' && <span className='iconwrap'><button className="plus" onClick={(e) => this.clickExternalLinkAddButton(e)} /></span>
                                                    }
                                                </a>
                                                {
                                                    node.children !== undefined
                                                    && node.children.length > 0 &&
                                                    this.renderChildren(node, node.children)
                                                }
                                                {
                                                    node.hasGroup !== undefined
                                                    && node.hasGroup
                                                    && this.state.loadings !== undefined
                                                    && this.state.loadings[node.id] !== undefined
                                                    && this.state.loadings[node.id] === true &&
                                                    <div style={{display: 'flex', justifyContent: 'center'}}><Loading size={10}/></div>
                                                }
                                                {
                                                    node.hasGroup !== undefined
                                                    && node.hasGroup
                                                    && node.group !== undefined
                                                    && node.group.length > 0 &&
                                                    <GroupContainer submenuId={menu.submenu.id} {...node} updateScrollbarPosition={this.updateScrollbarPosition}/>
                                                }
                                            </li>)
                                        }
                                    )
                        }
                    </ul>
                </div>
            </div>
        )
    }
}

export default connect(
    state => ({
        menu: state.menu,
        schedules: state.schedules,
        rulesets: state.rulesets
    }),
    dispatch => ({
        addTab: tab => dispatch(menuAction.addTab(tab)),
        moveTab: (tab, id, childrenId) => dispatch(menuAction.moveTab(tab, id, childrenId)),
        loadContent: id => dispatch(menuAction.loadContent(id)),
        forceReloadContent: () => dispatch(menuAction.forceReloadContent()),
        activeNode: id=> dispatch(menuAction.activeNode(id)),
        activeGroupNode: (id, noContentUpdate = false) => dispatch(menuAction.activeGroupNode(id, noContentUpdate)),
        activeChildGroupNode: (nodeId, id) => dispatch(menuAction.activeChildGroupNode(nodeId, id)),
        activeChildrenNode: (nodeId, id, lastId) => dispatch(menuAction.activeChildrenNode(nodeId, id, lastId)),
        loadGroup : (submenuId, nodeId) => dispatch(menuAction.loadGroup(submenuId, nodeId)),
        addPopup: (popup) => dispatch(popupAction.addPopup(popup)),
        closePopup:  popupId =>dispatch(popupAction.closePopup(popupId)),
        loadTrashBasketPopup: loadTrashBasket => dispatch(contentAction.loadTrashBasketPopup(loadTrashBasket)),
        loadTrashBasketPlaylistPopup: loadTrashBasket => dispatch(playlistAction.loadTrashBasketPopup(loadTrashBasket)),
        updateSubmenuChildren: (id, newChildren) => dispatch(menuAction.updateSubmenuChildren(id, newChildren))
    })

)(withTranslation()(SubMenuContainer));
